







































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default defineComponent({
  name: 'uOttawa1321Lab1Calculations',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      calculations: [] as File[],
    };
  },
  computed: {
    attachments(): File[] {
      return [...this.calculations];
    },
  },
});
